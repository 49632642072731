import './polyfills';

import { initMobileNav } from './header';
import { contactSubmit } from './contact.js';
import { initReviewsCaro } from './reviews';``;
import { initMap } from './map';
import { initializeFacilityCarousel, toggleMap, facility_rates, toggleFacInfo } from './facility';

initMobileNav();

const _doubleClick = () => {
	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			console.log(e);
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked') && window.screen.width > 750) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});
};

_doubleClick();

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('tip_list_wrap')) {
	document.getElementById('tip_list_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H2") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.toggle('active');
			}
			else {
				el.classList.add('active');
			}
		}
	});
}

if (document.querySelector('#reviews_container')) {
	initReviewsCaro();
}

if (document.querySelector('#map')) {
	initMap();
}

if (document.querySelector('#facility')) {
	initializeFacilityCarousel();
	toggleMap();
	facility_rates.init();
	toggleFacInfo();
}