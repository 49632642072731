import {
	AutomatitCarousel
} from './automatit_carousel.m';

var interval = 7000;

if(document.querySelectorAll('#reviews_container .caro_slide').length === 1){
	interval = false;
}

export const initReviewsCaro = () => {

	var caro = AutomatitCarousel({
		element: document.getElementById('reviews_container'),
		useChevrons: false,
		speed: 300,
		intervalTiming: interval
	});
    
	document.querySelector('#reviews_wrapper .left').addEventListener('click', function(){
		caro.slideLeft();
	});
	document.querySelector('#reviews_wrapper .right').addEventListener('click', function(){
		caro.slideRight();
	});
};