import { AutomatitCarousel } from './automatit_carousel.m';

export const initializeFacilityCarousel = () => {
	if (window.slide_images.length > 1) {
		const options = {
			element: document.getElementById('facility_carousel'),
			speed: 600,
			images: window.slide_images.slice(1),
			intervalTiming: 6000,
			useChevrons: false,
			imagesAsBackgrounds: true
		};
		let facCaro = AutomatitCarousel(options);
        
		document.querySelector('#facility_controls .left').addEventListener('click', function(){
			facCaro.slideLeft();
		});
		document.querySelector('#facility_controls .right').addEventListener('click', function(){
			facCaro.slideRight();
		});
	}
};

export const toggleMap = () => {
	[].forEach.call(document.querySelectorAll('#facility_controls .view_control'), function(btn){
		btn.addEventListener('click', (e) => {
			[].forEach.call(document.querySelectorAll('#facility_controls .view_control'), function(btn){
				btn.classList.remove('active');
			});
			let el = e.currentTarget;
			el.classList.add('active');
			if(el.dataset.content === 'map'){
				document.getElementById('facility_embed_map').classList.add('active');
			}else{
				document.getElementById('facility_embed_map').classList.remove('active');
			}
		});
	});
};

export const toggleFacInfo = () => {
	document.getElementById('view_info').addEventListener('click', () => {
		document.getElementById('facility_top').classList.toggle('active');
	});
};

export const facility_rates = (function(){
	let object = {},
		no_rates = document.getElementById('no_units'),
		units = document.querySelectorAll('#rates_table .unit_row'),
		filters = document.querySelectorAll('#facility_filters .filter');

	function _filterUnits(size){
		let count = 0;
		[].forEach.call(units, (unit) => {
			if(unit.dataset.size === size || size === 'all'){
				unit.style.display = 'flex';
				count++;
			}else{
				unit.style.display = 'none';
			}
		});
		if(count){
			no_rates.style.display = 'none';
		}else{
			no_rates.style.display = 'block';
		}
	}

	object.init = function(){
		[].forEach.call(filters, (filter) => {
			filter.addEventListener('click', (e) => {
				let filter = e.currentTarget;
				if(!filter.classList.contains('active')){
					document.querySelector('#facility_filters .active').classList.remove('active');
					filter.classList.add('active');
					_filterUnits(filter.dataset.size);
				}
			});
		});
		
		const searchParams = new URLSearchParams(window.location.search);
		if(searchParams.has('filter')) {
			document.getElementById('filter_'+searchParams.get('filter')).click();
		}
	};

	return object;
})();